import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import useToggler from '../hooks/useToggler'

export default function Navbar() {
  const token = localStorage.getItem('token')
  const location = useLocation()
  const { t } = useTranslation()
  const [isMenuOpen, toggleMenu, setIsMenuOpen] = useToggler(false)
  const [isDarkMode, toggleMode] = useToggler(() => {
    const localStorageValue = localStorage.getItem('dark-mode')
    return localStorageValue ? localStorageValue === 'true' : matchMedia('(prefers-color-scheme: dark)').matches
  })

  useEffect(() => {
    document.documentElement.className = isDarkMode ? 'dark' : ''
    localStorage.setItem('dark-mode', String(isDarkMode))
  }, [isDarkMode])

  const navItems = [
    {
      id: 'single',
      text: t('navSingle')
    },
    {
      id: 'multy',
      text: t('navMulty')
    },
    {
      id: 'contacts',
      text: t('navContacts')
    },
    {
      id: 'faq',
      text: 'FAQ'
    }
  ]

  return (
    <header className="mb-[80px]">
      <nav
        className="bg-blue-nav dark:bg-blue-nav-dark backdrop-blur-md h-[80px] px-6 text-very-light-pinkish-blue dark:text-very-light-blue font-roboto font-bold fixed z-10 top-0 w-full md:px-10">
        <div className="max-w-container mx-auto h-full flex items-center justify-between">
          <a href="/#" className="flex items-center gap-4">
            <img src="/avatar.png" alt="Avatar" className="h-[56px]" />
            <img src="/logo.svg" alt="Logo" className="hidden md:block" />
          </a>

          <ul className="hidden xl:flex w-full h-4/5 justify-center gap-8">
            {navItems.map(navItem => (
              <li key={navItem.id}>
                <a
                  href={`/#${navItem.id}`}
                  className="flex justify-center items-center h-full transition-colors hover:text-white dark:hover:text-blue-hover"
                >
                  {navItem.text}
                </a>
              </li>
            ))}
          </ul>

          <div className="flex gap-4 items-center">
            {/* Login link, positioned to the right on both small and large screens */}
            <button><a href='/login'>{location.pathname === '/login' ? '' : token ? 'Logout' : 'Login'}</a></button>

            {/* Burger menu, shown only on small screens */}
            <button
              aria-label="Toggle menu"
              className="ml-auto text-[1.5rem] leading-5 xl:hidden"
              onClick={toggleMenu}
              aria-expanded={isMenuOpen}
            >
              {isMenuOpen ? (
                <i className="fa-solid fa-close" aria-hidden="true"></i>
              ) : (
                <i className="fa-solid fa-bars" aria-hidden="true"></i>
              )}
            </button>
          </div>
        </div>
      </nav>

      {isMenuOpen && (
        <div
          className="fixed z-10 overflow-auto top-[80px] left-0 w-full h-[calc(100dvh-80px)] bg-blue-nav dark:bg-blue-nav-dark text-very-light-pinkish-blue dark:text-very-light-blue font-roboto font-bold px-6 pt-6 pb-8 flex flex-col justify-between xl:hidden backdrop-blur-md">
          <ul className="text-center">
            {navItems.map(navItem => (
              <li key={navItem.id} className="mobile-menu-item border-t-[1px] border-line-light dark:border-line">
                <a href={`#${navItem.id}`} className="inline-block w-full py-4"
                   onClick={() => setIsMenuOpen(false)}>
                  {navItem.text}
                </a>
              </li>
            ))}
          </ul>

          <div className="mt-8 flex flex-col items-center gap-5 font-medium">
            <button className="flex items-center gap-3" onClick={toggleMode}>
              <span>{isDarkMode ? t('darkMode') : t('lightMode')}</span>
              <i className={`fa-solid ${isDarkMode ? 'fa-moon' : 'fa-sun'}`} aria-hidden="true"></i>
            </button>
          </div>
        </div>
      )}
    </header>

  )
}
