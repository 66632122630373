interface IAnimationBase {
  enter: string
  leave: string
}

interface IAnimation extends IAnimationBase {
  enterFrom: string
  enterTo: string
  leaveFrom: string
  leaveTo: string
}

const baseProps: IAnimationBase = {
  enter: 'transition duration-200',
  leave: 'transition duration-200'
}

export const fade: IAnimation = {
  ...baseProps,
  enterFrom: 'opacity-0',
  enterTo: 'opacity-100',
  leaveFrom: 'opacity-100',
  leaveTo: 'opacity-0'
}

export const fadeScale: IAnimation = {
  ...baseProps,
  enterFrom: 'opacity-0 scale-95',
  enterTo: 'opacity-100 scale-100',
  leaveFrom: 'opacity-100 scale-100',
  leaveTo: 'opacity-0 scale-95'
}
