import { Column, SortingType } from '../types/single'

export const columns: Column[] = [
  {
    text: 'Ticker',
    width: 65,
    sortFunction: (a, b, sortingType) =>
      sortingType === SortingType.DESC ? b.symbol.localeCompare(a.symbol) : a.symbol.localeCompare(b.symbol)
  },
  {
    text: 'Buy count',
    width: 78,
    sortFunction: (a, b, sortingType) => (sortingType === SortingType.DESC ? a.buy.count - b.buy.count : b.buy.count - a.buy.count)
  },
  {
    text: 'Sell count',
    width: 76,
    sortFunction: (a, b, sortingType) => (sortingType === SortingType.DESC ? a.sell.count - b.sell.count : b.sell.count - a.sell.count)
  },
  {
    text: 'Fee, USD',
    width: 68,
    sortFunction: (a, b, sortingType) => (sortingType === SortingType.DESC ? a.feeUsd - b.feeUsd : b.feeUsd - a.feeUsd)
  },
  {
    text: 'Diff. Count',
    width: 80,
    sortFunction: (a, b, sortingType) => (sortingType === SortingType.DESC ? a.diffCount - b.diffCount : b.diffCount - a.diffCount)
  },
  {
    text: 'Buy Count, $',
    width: 93,
    sortFunction: (a, b, sortingType) =>
      sortingType === SortingType.DESC ? a.buy.countUsd - b.buy.countUsd : b.buy.countUsd - a.buy.countUsd
  },
  {
    text: 'Sell Count, $',
    width: 92,
    sortFunction: (a, b, sortingType) =>
      sortingType === SortingType.DESC ? a.sell.countUsd - b.sell.countUsd : b.sell.countUsd - a.sell.countUsd
  },
  {
    text: 'Profit, $',
    width: 72,
    sortFunction: (a, b, sortingType) =>
      sortingType === SortingType.DESC ? a.diffCountUsd - b.diffCountUsd : b.diffCountUsd - a.diffCountUsd
  },
  {
    text: 'Profit, %',
    width: 64,
    sortFunction: (a, b, sortingType) => (sortingType === SortingType.DESC ? a.diffPrice - b.diffPrice : b.diffPrice - a.diffPrice)
  },
  {
    text: 'Balance, $',
    width: 94,
    sortFunction: (a, b, sortingType) =>
      sortingType === SortingType.DESC ? a.diffCountUsd - b.diffCountUsd : b.diffCountUsd - a.diffCountUsd
  },
  {
    text: 'Buys',
    width: 50,
    sortFunction: (a, b, sortingType) =>
      sortingType === SortingType.DESC ? a.buy.countTransfers - b.buy.countTransfers : b.buy.countTransfers - a.buy.countTransfers
  },
  {
    text: 'Sells',
    width: 50,
    sortFunction: (a, b, sortingType) =>
      sortingType === SortingType.DESC ? a.sell.countTransfers - b.sell.countTransfers : b.sell.countTransfers - a.sell.countTransfers
  },
  {
    text: 'Last tx',
    width: 85,
    sortFunction: (a, b, sortingType) =>
      sortingType === SortingType.DESC ? a.lastTimestamp - b.lastTimestamp : b.lastTimestamp - a.lastTimestamp
  },
  {
    text: 'Liquidity, $',
    width: 82,
    sortFunction: (a, b, sortingType) => (sortingType === SortingType.DESC ? a.liquidity - b.liquidity : b.liquidity - a.liquidity)
  },
  {
    text: 'Token',
    width: 77,
    sortFunction: (a, b, sortingType) =>
      sortingType === SortingType.DESC ? b.address.localeCompare(a.address) : a.address.localeCompare(b.address)
  }
]
