import { Disclosure } from '@headlessui/react'
import { FAQCard } from '../types/faq'

type QuestionCardProps = {
  item: FAQCard
}

export default function QuestionCard({ item }: QuestionCardProps) {
  return (
    <Disclosure>
      {({ open }) => (
        <div className="px-5 py-4 bg-white dark:bg-blue rounded-lg md:px-6 md:py-5">
          <Disclosure.Button className="w-full text-[1rem] md:text-h4 font-semibold flex justify-between items-center">
            <span>{item.title}</span>
            <i className="fa-solid fa-chevron-down transition-transform" style={{ transform: open ? 'rotate(180deg)' : '' }}></i>
          </Disclosure.Button>
          <Disclosure.Panel className="mt-4 text-[0.875rem] md:text-[1rem] whitespace-break-spaces" as="p">
            {item.text}
          </Disclosure.Panel>
        </div>
      )}
    </Disclosure>
  )
}
