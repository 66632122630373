import { Navigate } from 'react-router-dom'

interface ProtectedRouteProps {
    children: JSX.Element
}

const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
    const token = localStorage.getItem('token')

    if (!token) {
        return <Navigate to="/about" replace />
    }

    return children
}

export default ProtectedRoute
