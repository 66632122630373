import { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { fade } from '../utils/transitions'

type SelectProps = {
  items: string[]
  value: string
  onChange: () => void
}

export default function Select({ items, value, onChange }: SelectProps) {
  return (
    <Listbox value={value} onChange={onChange}>
      <div className="relative w-full">
        <Listbox.Button className="px-4 py-[0.625rem] bg-modal-input dark:bg-modal-input-dark rounded-md w-full text-left font-medium dark:text-[#BCBDDE] flex justify-between items-center">
          <span>{value}</span>
          <img src="/up-down.svg" alt="Up down icon" aria-hidden="true" />
        </Listbox.Button>
        <Transition {...fade} as={Fragment}>
          <Listbox.Options className="absolute z-10 mt-2 max-h-60 w-full overflow-auto rounded-md bg-modal-input dark:bg-modal-input-dark ring-opacity-5 focus:outline-none custom-scrollbar">
            {items.map((item, index) => (
              <Listbox.Option key={index} value={item} as={Fragment}>
                {({ selected, active }) => (
                  <li
                    className={`cursor-pointer px-4 py-3 flex items-center gap-3 ${
                      active ? 'bg-listbox-hover dark:bg-listbox-hover-dark' : ''
                    }`}
                  >
                    <i className={`fa-solid fa-check ${selected ? 'visible' : 'invisible'} text-[#BCBDDE]`}></i>
                    <span className={`${selected ? 'font-bold' : 'font-normal'}`}>{item}</span>
                  </li>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  )
}
