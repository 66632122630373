import './instrument.js'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import { initializeI18n } from './utils/localization.ts'
import { initializeMixpanel } from './utils/mixpanel.ts'

import './index.css'

initializeI18n()
initializeMixpanel()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(<App />)
