import { useState } from 'react'

export default function useCopy() {
  const [copiedValue, setCopiedValue] = useState<string>('')

  function applyCopy(text: string) {
    setCopiedValue(text)
    navigator.clipboard.writeText(text)
  }

  return {
    copiedValue,
    applyCopy
  }
}
