import MultySection from '../components/Multy/MultySection'
import SingleSection from '../components/Single/SingleSection'
import FAQ from '../components/FAQ'

export default function Homepage() {
  return (
    <>
      <SingleSection isHomepage={true} />
      <MultySection />
      <FAQ />
    </>
  )
}
