import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
// import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'

export const languages = {
  en: 'English'
  // ru: 'Русский'
}

export function initializeI18n() {
  i18n
    .use(initReactI18next)
    // .use(LanguageDetector)
    .use(Backend)
    .init({
      fallbackLng: 'en',
      backend: {
        loadPath: '/locales/{{lng}}.json'
      }
    })
}
