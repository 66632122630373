import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {SortingType, TableData} from '../../types/multy'
import {getFormattedNumber, getSeparatedNumber, getTooltipClasses, getValueColor} from '../../utils/shared'
import {columns} from '../../data/multyTable'
import useToggler from '../../hooks/useToggler'
import useCopy from '../../hooks/useCopy'

type MultyTableProps = {
    tableData?: TableData[],
    isLoading: boolean,
    timeframe?: string
}

export default function MultyTable({tableData, isLoading, timeframe}: MultyTableProps) {
    const {t} = useTranslation()

    const [isTableExpanded, toggleIsTableExpanded] = useToggler(false)
    const [sortedColumnIndex, setSortedColumnIndex] = useState<number>(3)
    const [sortType, setsortType] = useState<SortingType>(SortingType.ASC)
    const {copiedValue, applyCopy} = useCopy()

    const skeletons = new Array(10)
        .fill(null)
        .map((_, index) => <div key={index}
                                className="w-[1252px] h-[18px] bg-skeleton dark:bg-skeleton-dark animate-pulse rounded-md"></div>)

    function handleColumnClick(columnIndex: number) {
        if (columnIndex === 0) return

        if (sortedColumnIndex === columnIndex) {
            setsortType(prevsortType => (prevsortType === SortingType.ASC ? SortingType.DESC : SortingType.ASC))
        }

        setSortedColumnIndex(columnIndex)
    }

    const columnElements = columns.map((column, index) => {
        return (
            <button
                key={index}
                className="flex justify-center gap-[0.375rem] items-center shrink-0"
                style={{width: `${column.width}px`, cursor: index === 0 ? 'auto' : 'pointer'}}
                title={column.description}
                onClick={() => handleColumnClick(index)}
            >
                <span>{column.shortName}</span>
                {index !== 0 && sortedColumnIndex === index && (
                    <i className={`fa-solid fa-chevron-down ${sortType === SortingType.DESC ? 'fa-rotate-180' : ''}`}></i>
                )}
            </button>
        )
    })

    const currentColumn = columns[sortedColumnIndex]

    const rowElements = tableData
        ?.sort((a, b) => currentColumn.sortFunction(a, b, sortType))
        .slice(0, isTableExpanded ? 100 : 10)
        .map((row, index) => (
            <div key={index} className="flex gap-4 justify-between">
                <div className="shrink-0 w-[14px]">{index + 1}</div>
                <div className="w-[330px] shrink-0 flex justify-between items-center">
                    <button className="text-left tooltip tooltip-center"
                            data-tooltip={copiedValue === row.wallet ? t('copied') : t('clickToCopy')}>
                        <div className="font-mono" onClick={() => applyCopy(row.wallet)}>
                            {row.wallet}
                        </div>
                    </button>
                    <a
                        href={`/analytics?wallet=${row.wallet}&timeframe=${timeframe}`}
                        target="_blank"
                        aria-label="Go to analytics"
                        className="tooltip tooltip-center"
                        data-tooltip={t('goToAnalytics')}
                    >
                        <i className="fa-solid fa-up-right-from-square"></i>
                    </a>
                </div>
                <div className={`w-[96px] shrink-0 ${getTooltipClasses(row.stats.total.winrate * 100)}`}>
                    {(row.stats.total.winrate * 100).toFixed(2)}%
                </div>
                <div
                    className={`w-[90px] shrink-0 ${getTooltipClasses(row.stats.total.pnl)}`}
                    style={{color: getValueColor(row.stats.total.pnl)}}
                    data-tooltip={getSeparatedNumber(row.stats.total.pnl, 2)}
                >
                    <div>{getFormattedNumber(row.stats.total.pnl)}$</div>
                </div>
                <div
                    className={`w-[82px] shrink-0 ${getTooltipClasses(row.stats.total.rateDeposit * 100)}`}
                    style={{color: getValueColor(row.stats.total.rateDeposit)}}
                    data-tooltip={getSeparatedNumber(row.stats.total.rateDeposit * 100)}
                >
                    <div>{getFormattedNumber(row.stats.total.rateDeposit * 100)}%</div>
                </div>
                <div
                    className={`w-[100px] shrink-0 ${getTooltipClasses(row.stats.total.rateAverage * 100)}`}
                    data-tooltip={getSeparatedNumber(row.stats.total.rateAverage * 100)}
                >
                    <div>{getFormattedNumber(row.stats.total.rateAverage * 100)}%</div>
                </div>
                <div
                    className={`w-[82px] shrink-0 ${getTooltipClasses(row.tokenCount ?? 0)}`}
                    data-tooltip={getSeparatedNumber(row.tokenCount ?? 0, 0)}
                >
                    <div>{getFormattedNumber(row.tokenCount ?? 0, 0)}</div>
                </div>
                <div
                    className={`w-[82px] shrink-0 ${getTooltipClasses(row.transfersCount ?? 0)}`}
                    data-tooltip={getSeparatedNumber(row.transfersCount ?? 0, 2)}
                >
                    <div>{getFormattedNumber(row.transfersCount ?? 0, 0)}</div>
                </div>
                <div
                    className={`w-[100px] shrink-0 ${getTooltipClasses((row.scamRate ?? 0) * 100)}`}
                    data-tooltip={getSeparatedNumber((row.scamRate ?? 0) * 100)}
                >
                    <div>{getFormattedNumber((row.scamRate ?? 0) * 100)}%</div>
                </div>
                <div
                    className={`w-[82px] shrink-0`}
                    data-tooltip={row.lastTrade}
                >
                    <div>{row.lastTrade ? new Date(row.lastTrade).toLocaleDateString('en-GB').slice(0, 10) : ''}</div>
                </div>
            </div>
        ))

    return (
        <>
            <div className="text-[0.75rem] overflow-x-auto bg-white dark:bg-blue rounded-md text-center">
                <div
                    className="flex justify-between items-center font-semibold min-w-max px-6 py-5 gap-4 border-b-[1px] border-b-divider dark:border-b-divider-dark">
                    {columnElements}
                </div>
                <div className="p-6 flex flex-col gap-[0.875rem] xl:gap-y-6">
                    {isLoading ? skeletons : tableData && tableData.length > 0 ? rowElements :
                        <p className="text-[1rem]">No wallets</p>}
                </div>
            </div>
            {tableData && tableData.length > 10 && (
                <button
                    className="mt-6 self-center font-semibold text-[0.875rem] bg-white dark:bg-blue px-4 py-2 rounded-md"
                    onClick={toggleIsTableExpanded}
                >
                    <span>{isTableExpanded ? t('showLess') : t('showAll')}</span>
                    <i className="ml-2 fa-solid fa-chevron-down"
                       style={{transform: isTableExpanded ? 'rotate(180deg)' : ''}}></i>
                </button>
            )}
        </>
    )
}
