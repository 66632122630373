import { Column, Filter, SelectType, SortingType } from '../types/multy'

export const filters: Filter[] = [
  {
    key: 'totalWinRate',
    name: 'Winrate',
    type: SelectType.VALUES,
    unit: '%'
  },
  {
    key: 'totalPnL',
    name: 'PnL',
    type: SelectType.VALUES,
    unit: '$'
  },
  {
    key: 'totalDeposit',
    name: 'PnL',
    type: SelectType.VALUES,
    unit: '%'
  },
  {
    key: 'totalAverage',
    name: 'Average',
    type: SelectType.VALUES,
    unit: '%'
  },
  {
    key: 'tokenCount',
    name: 'Count of tokens',
    type: SelectType.VALUES,
    unit: ''
  },
  {
    key: 'scamRate',
    name: 'Scam rate',
    type: SelectType.VALUES,
    unit: '%'
  },
  {
    key: 'transfersCount',
    name: 'Count of transfers',
    type: SelectType.VALUES,
    unit: ''
  },
  // {
  //   key: 'minLiquidity',
  //   name: 'Min. liquidity of tokens',
  //   type: SelectType.DROPDOWN,
  //   options: ['Option 1', 'Option 2', 'Option 3', 'Option 4', 'Option 5']
  // },
  // {
  //   key: 'minVolume',
  //   name: 'Min. volume of tokens',
  //   type: SelectType.DROPDOWN,
  //   options: ['Option 1', 'Option 2', 'Option 3', 'Option 4', 'Option 5']
  // },
]

export const columns: Column[] = [
  {
    shortName: '#',
    description: '#',
    width: 14,
    sortFunction: () => 0
  },
  {
    shortName: 'Wallet',
    description: 'Wallet',
    width: 330,
    sortFunction: (a, b, sortOrder) =>
      sortOrder === SortingType.DESC ? b.wallet.localeCompare(a.wallet) : a.wallet.localeCompare(b.wallet)
  },
  {
    shortName: 'Winrate, %',
    description: 'Winrate, %',
    width: 96,
    sortFunction: (a, b, sortOrder) =>
      sortOrder === SortingType.DESC ? a.stats.total.winrate - b.stats.total.winrate : b.stats.total.winrate - a.stats.total.winrate
  },
  {
    shortName: 'PnL, $',
    description: 'PnL, $',
    width: 90,
    sortFunction: (a, b, sortOrder) =>
      sortOrder === SortingType.DESC ? a.stats.total.pnl - b.stats.total.pnl : b.stats.total.pnl - a.stats.total.pnl
  },
  {
    shortName: 'PnL, %',
    description: 'PnL, %',
    width: 82,
    sortFunction: (a, b, sortOrder) =>
      sortOrder === SortingType.DESC
        ? a.stats.total.rateDeposit - b.stats.total.rateDeposit
        : b.stats.total.rateDeposit - a.stats.total.rateDeposit
  },
  {
    shortName: 'Average, %',
    description: 'Average, %',
    width: 100,
    sortFunction: (a, b, sortOrder) =>
      sortOrder === SortingType.DESC
        ? a.stats.total.rateAverage - b.stats.total.rateAverage
        : b.stats.total.rateAverage - a.stats.total.rateAverage
  },
  {
    shortName: 'Tokens',
    description: 'Tokens',
    width: 82,
    sortFunction: (a, b, sortOrder) =>
      sortOrder === SortingType.DESC ? (a.tokenCount ?? 0) - (b.tokenCount ?? 0) : (b.tokenCount ?? 0) - (a.tokenCount ?? 0)
  },
  {
    shortName: 'Trades',
    description: 'Trades',
    width: 82,
    sortFunction: (a, b, sortOrder) =>
      sortOrder === SortingType.DESC ? (a.transfersCount ?? 0) - (b.transfersCount ?? 0) : (b.transfersCount ?? 0) - (a.transfersCount ?? 0)
  },
  {
    shortName: 'Scam rate, %',
    description: 'Scam rate, %',
    width: 100,
    sortFunction: (a, b, sortOrder) =>
      sortOrder === SortingType.DESC ? (a.scamRate ?? 0) - (b.scamRate ?? 0) : (b.scamRate ?? 0) - (a.scamRate ?? 0)
  },
  {
    width: 82,
    shortName: 'Last trade',
    description: 'Last trade',
    sortFunction: (a, b, sortOrder) =>
        sortOrder === SortingType.DESC ? (new Date(a.lastTrade!).getTime() ?? 0) - (new Date(b.lastTrade!).getTime() ?? 0) : (new Date(b.lastTrade!).getTime() ?? 0) - (new Date(a.lastTrade!).getTime() ?? 0)
  }
]
