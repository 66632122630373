import { SingleWalletStat } from '../../types/single'
import { getFormattedNumber, getSeparatedNumber, getTooltipClasses, getValueColor } from '../../utils/shared'

type StatCardProps = {
  title: string
  stat: SingleWalletStat
}

type StatValue = {
  name: string
  value: number
  symbol: '%' | '$'
  isColored: boolean
}

export default function StatCard({ title, stat }: StatCardProps) {
  const statValues: StatValue[] = [
    {
      name: 'Winrate',
      value: stat.winrate * 100,
      symbol: '%',
      isColored: false
    },
    {
      name: 'PnL',
      value: stat.pnl,
      symbol: '$',
      isColored: true
    },
    {
      name: 'PnL',
      value: stat.rateDeposit * 100,
      symbol: '%',
      isColored: true
    },
    {
      name: 'Average',
      value: stat.rateAverage * 100,
      symbol: '%',
      isColored: false
    }
  ]

  const statItems = statValues.map((item, index) => (
    <div className="flex-1" key={index}>
      <div
        className={`font-bold text-[1rem] md:text-h2 ${getTooltipClasses(item.value)}`}
        style={{ color: item.isColored ? getValueColor(item.value) : '' }}
        data-tooltip={getSeparatedNumber(item.value, 2)}
      >
        <div>
          {getFormattedNumber(item.value, 0)}
          {item.symbol}
        </div>
      </div>
      <p className="mt-1 text-[0.625rem] text-dark-gray dark:text-very-light-blue md:text-[0.875rem] md:mt-0">
        {item.name}, {item.symbol}
      </p>
    </div>
  ))

  return (
    <div className="w-full flex flex-col">
      <h3 className="text-h4 font-bold md:text-h3">{title}</h3>
      <div className="flex-1 mt-5 bg-white dark:bg-blue px-4 py-[1.875rem] rounded-lg flex justify-between items-center text-center md:px-8 md:py-10 md:gap-3">
        {statItems}
      </div>
    </div>
  )
}
