type MainProps = {
  title: string
  children: React.ReactNode
}

export default function Main({ title, children }: MainProps) {
  return (
    <main className="flex-1 px-6 md:px-10">
      <h1 className="sr-only">{title}</h1>
      <div className="max-w-container mx-auto">{children}</div>
    </main>
  )
}
