import mixpanel, { Dict } from 'mixpanel-browser'

const MIXPANEL_TOKEN = import.meta.env.VITE_MIXPANEL_TOKEN
const USER_SESSION_KEY = 'mp_uuid'

export function initializeMixpanel() {
  let sessionUUID = localStorage.getItem(USER_SESSION_KEY)

  if (!sessionUUID) {
    const randomUUID = crypto.randomUUID()
    sessionUUID = randomUUID
    localStorage.setItem(USER_SESSION_KEY, sessionUUID)
  }

  mixpanel.init(MIXPANEL_TOKEN, {
    debug: import.meta.env.DEV,
    track_pageview: true,
    persistence: 'localStorage'
  })

  mixpanel.identify(sessionUUID)
}

export function trackEvent(name: string, props?: Dict) {
  mixpanel.track(name, props)
}
