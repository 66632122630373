import { useState } from 'react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { SingleTokenInfo, SortingType } from '../../types/single'
import { getFormattedNumber, getSeparatedNumber, getTooltipClasses, getValueColor } from '../../utils/shared'
import { columns } from '../../data/singleTable'
import useCopy from '../../hooks/useCopy'

interface SingleTableProps {
  tokenList: SingleTokenInfo[]
}

export default function SingleTable({ tokenList }: SingleTableProps) {
  const { t } = useTranslation()

  const [sortedColumnIndex, setSortedColumnIndex] = useState<number | null>(null)
  const [sortingType, setSortingType] = useState<SortingType>(SortingType.OFF)
  const { copiedValue, applyCopy } = useCopy()

  console.log(tokenList)

  function handleColumnClick(index: number) {
    setSortedColumnIndex(index)

    if (sortedColumnIndex === index) {
      setSortingType(prevSortingType => (prevSortingType === 2 ? 0 : prevSortingType + 1))
      return
    }

    setSortingType(SortingType.ASC)
  }

  const sortedRows =
    sortingType === SortingType.OFF
      ? tokenList
      : tokenList.slice(0).sort((a, b) => columns[sortedColumnIndex || 0].sortFunction(a, b, sortingType))

  const rows = sortedRows.map((token, index) => {
    let warningIcon = null
    let warningText = ''

    if (token.diffCount < 0) {
      warningIcon = <i className="fa-regular fa-clock"></i>
      warningText = t('beforeUnloading')
    } else if (token.scam) {
      warningIcon = <i className="fa-solid fa-triangle-exclamation"></i>
      warningText = t('possibleScam')
    }

    return (
      <div className="flex gap-2 justify-between" key={index}>
        <div className={`shrink-0 w-[65px] ${warningIcon ? 'tooltip tooltip-left' : ''}`} data-tooltip={warningText}>
          <div className="flex items-center gap-2 justify-start">
            {warningIcon ?? <i className="w-[12px] h-[12px]" />}
            <span title={token.symbol}>{token.symbol.length > 5 ? token.symbol.slice(0, 5) + '...' : token.symbol}</span>
          </div>
        </div>
        <div className={`shrink-0 w-[78px] ${getTooltipClasses(token.buy.count)}`} data-tooltip={getSeparatedNumber(token.buy.count, 2)}>
          <div>{getFormattedNumber(token.buy.count)}</div>
        </div>
        <div className={`shrink-0 w-[76px] ${getTooltipClasses(token.sell.count)}`} data-tooltip={getSeparatedNumber(token.sell.count, 2)}>
          <div>{getFormattedNumber(token.sell.count)}</div>
        </div>
        <div className={`shrink-0 w-[68px] ${getTooltipClasses(token.feeUsd)}`} data-tooltip={getSeparatedNumber(token.feeUsd, 2)}>
          <div>{getFormattedNumber(token.feeUsd)}</div>
        </div>
        <div className="shrink-0 w-[80px]">
          <div
            className={`flex justify-center items-center gap-2 ${getTooltipClasses(token.diffCount)}`}
            data-tooltip={getSeparatedNumber(token.diffCount, 2)}
          >
            <div>{getFormattedNumber(token.diffCount)}</div>
          </div>
        </div>
        <div
          className={`shrink-0 w-[93px] ${getTooltipClasses(token.buy.countUsd)}`}
          data-tooltip={getSeparatedNumber(token.buy.countUsd, 2)}
        >
          <div>{getFormattedNumber(token.buy.countUsd)}</div>
        </div>
        <div
          className={`shrink-0 w-[92px] ${getTooltipClasses(token.sell.countUsd)}`}
          data-tooltip={getSeparatedNumber(token.sell.countUsd)}
        >
          <div>{getFormattedNumber(token.sell.countUsd)}</div>
        </div>
        <div
          className={`shrink-0 w-[72px] ${getTooltipClasses(token.diffCountUsd)}`}
          data-tooltip={getSeparatedNumber(token.diffCountUsd)}
          style={{ color: getValueColor(token.diffCountUsd) }}
        >
          <div>{getFormattedNumber(token.diffCountUsd)}</div>
        </div>
        <div
          className={`shrink-0 w-[64px] ${getTooltipClasses(token.diffPrice * 100)}`}
          style={{ color: getValueColor(token.diffPrice) }}
          data-tooltip={getSeparatedNumber(token.diffPrice * 100)}
        >
          <div>{getFormattedNumber(token.diffPrice * 100)}%</div>
        </div>
        <div
          className={`shrink-0 w-[94px] ${getTooltipClasses(token.remainUsd)}`}
          data-tooltip={getSeparatedNumber(token.remainUsd)}
          style={{ color: getValueColor(token.remainUsd) }}
        >
          <div>{getFormattedNumber(token.remainUsd)}</div>
        </div>
        <div
          className={`shrink-0 w-[50px] ${getTooltipClasses(token.buy.countTransfers)}`}
          data-tooltip={getSeparatedNumber(token.buy.countTransfers, 0)}
        >
          <div>{token.buy.countTransfers}</div>
        </div>
        <div
          className={`shrink-0 w-[50px] ${getTooltipClasses(token.sell.countTransfers)}`}
          data-tooltip={getSeparatedNumber(token.sell.countTransfers, 0)}
        >
          <div>{token.sell.countTransfers}</div>
        </div>
        <div className="shrink-0 w-[85px]">{dayjs.unix(token.lastTimestamp).format('DD.MM.YY HH:mm')}</div>
        <div className={`shrink-0 w-[82px] ${getTooltipClasses(token.liquidity)}`} data-tooltip={getSeparatedNumber(token.liquidity, 2)}>
          <div>{getFormattedNumber(token.liquidity)}</div>
        </div>
        <div
          className="shrink-0 w-[77px] cursor-pointer tooltip tooltip-center"
          data-tooltip={copiedValue === token.address ? t('copied') : t('clickToCopy')}
        >
          <div onClick={() => applyCopy(token.address)}>
            <span className="font-mono">{token.address.slice(0, 9)}</span>
            <span>...</span>
          </div>
        </div>
      </div>
    )
  })

  return (
    <div className="text-[0.75rem] overflow-x-auto bg-white dark:bg-blue rounded-md text-center">
      <div className="flex min-w-max px-3 py-5 gap-2 border-b-[1px] justify-between border-b-divider dark:border-b-divider-dark font-semibold">
        {columns.map((column, index) => (
          <div
            key={index}
            className="shrink-0 cursor-pointer flex items-center justify-center gap-1"
            style={{ width: column.width }}
            onClick={() => handleColumnClick(index)}
          >
            <span>{column.text}</span>
            {sortingType !== SortingType.OFF && sortedColumnIndex === index && (
              <i className={`fa-solid fa-chevron-down ${sortingType === SortingType.DESC ? 'fa-rotate-180' : ''}`}></i>
            )}
          </div>
        ))}
      </div>
      <div className="px-3 py-5 flex flex-col gap-[0.875rem] xl:gap-y-6">
        {rows.length > 0 ? rows : <p className="text-[0.875rem]">No data</p>}
      </div>
    </div>
  )
}
