import { Dispatch, SetStateAction, useState } from 'react'

type DefaultValue = (() => boolean) | boolean
type TogglerReturn = [boolean, () => void, Dispatch<SetStateAction<boolean>>]

export default function useToggler(defaultValue: DefaultValue): TogglerReturn {
  const [state, setState] = useState<boolean>(defaultValue)

  function toggleState() {
    setState(prevState => !prevState)
  }

  return [state, toggleState, setState]
}
