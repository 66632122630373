import QuestionCard from './QuestionCard'
import { FAQCard } from '../types/faq'

const questions: FAQCard[] = [
  {
    id: 1,
    title: 'About',
    text: `CLEOPATRA ANALYTICAL SERVICE FOR MONITORING CRYPTO WALLETS

WE SCAN ALL BLOCKCHAIN NETWORK TRANSACTIONS TO OBTAIN COMPREHENSIVE
INFORMATION ABOUT ALL TRANSACTIONS WHERE INDIVIDUAL PARTICIPANTS IN THE DEX MARKET HAVE MADE A PROFIT AND PROVIDE YOU WITH A LIST OF THE TOP100 SUCCESSFUL WALLETS
`
  }
]

export default function FAQ() {
  const questionCards = questions.map(item => <QuestionCard key={item.id} item={item} />)

  return (
    <section className="mt-16 md:mt-20" id="faq">
      <h2 className="text-h2 font-bold">FAQ</h2>
      <div className="mt-6 flex flex-col gap-4 md:gap-6">{questionCards}</div>
    </section>
  )
}
