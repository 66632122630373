import { Fragment } from 'react'
import { Dialog, Disclosure, Transition } from '@headlessui/react'
import { fade, fadeScale } from '../../utils/transitions'
import { Controller, useForm } from 'react-hook-form'
import Select from '../Listbox'
import { AnyObject, SelectType } from '../../types/multy'
import { useTranslation } from 'react-i18next'
import { filters } from '../../data/multyTable'
import { transformFilters } from '../../utils/shared'

type FilterProps = {
  currentFilters: AnyObject
  isModalOpen: boolean
  setIsModalOpen: (open: boolean) => void
  onSubmit: (data: AnyObject) => void
  resetFilters: () => void
}

type FieldErrorType = {
  start?: string
  end?: string
}

export default function Filters({ isModalOpen, setIsModalOpen, currentFilters, onSubmit, resetFilters }: FilterProps) {
  const { t } = useTranslation()

  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    defaultValues: transformFilters(currentFilters, false)
  })

  function closeModal() {
    setIsModalOpen(false)
  }

  const filterElements = filters.map(filter => (
    <Disclosure key={filter.key} defaultOpen={currentFilters[filter.key]?.start || currentFilters[filter.key]?.end}>
      {({ open }) => (
        <div>
          <Disclosure.Button className="w-full flex justify-between items-center focus-visible:outline-white">
            <span>
              {filter.name}
              {filter.unit && `, ${filter.unit}`}
            </span>
            <i
              className="fa-solid fa-chevron-down fa-lg text-modal-gray transition-transform"
              aria-hidden="true"
              style={{ transform: open ? 'rotate(180deg)' : '' }}
            ></i>
          </Disclosure.Button>
          <Disclosure.Panel className="pt-3 md:pt-4">
            {filter.type === SelectType.VALUES ? (
              <div className="flex gap-5">
                <input
                  {...register(`${filter.key}.start`, {
                    min: 0,
                    max: 1_000_000,
                    setValueAs: value => Number(value)
                  })}
                  type="number"
                  className="px-4 py-[0.625rem] bg-modal-input dark:bg-modal-input-dark rounded-md font-medium w-full dark:placeholder:text-[#BCBDDE]"
                  placeholder={t('startValue')}
                  style={{ outline: (errors[filter.key] as FieldErrorType)?.start ? '1px solid #E85D75' : '' }}
                />
                <input
                  {...register(`${filter.key}.end`, {
                    min: 0,
                    max: 1_000_000,
                    setValueAs: value => Number(value)
                  })}
                  type="number"
                  className="px-4 py-[0.625rem] bg-modal-input dark:bg-modal-input-dark rounded-md font-medium w-full dark:placeholder:text-[#BCBDDE]"
                  placeholder={t('endValue')}
                  style={{ outline: (errors[filter.key] as FieldErrorType)?.end ? '1px solid #E85D75' : '' }}
                />
              </div>
            ) : (
              <Controller
                control={control}
                name={filter.key}
                defaultValue={filter.options[0]}
                render={({ field: { value, onChange } }) => <Select value={value} onChange={onChange} items={filter.options} />}
              />
            )}
          </Disclosure.Panel>
        </div>
      )}
    </Disclosure>
  ))

  return (
    <Transition show={isModalOpen} as={Fragment}>
      <Dialog onClose={() => setIsModalOpen(false)} className="relative z-50">
        <Transition.Child as={Fragment} {...fade}>
          <div className="fixed inset-0 bg-overlay" />
        </Transition.Child>
        <div className="fixed inset-0 w-screen overflow-y-hidden">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child as={Fragment} {...fadeScale}>
              <Dialog.Panel className="modal mx-auto max-w-[600px] w-full bg-white dark:bg-blue rounded-lg text-[0.875rem]">
                <div className="flex justify-between items-center border-b-[1px] border-divider dark:border-divider-dark px-6 py-4 md:px-8 md:py-6">
                  <Dialog.Title className="text-h4 font-bold md:text-h3">{t('filters')}</Dialog.Title>
                  <button aria-label="Close modal" className="text-modal-gray hover:opacity-80 transition-opacity" onClick={closeModal}>
                    <i className="fa-solid fa-xmark fa-xl" aria-hidden="true" />
                  </button>
                </div>
                <form autoComplete="off" onSubmit={handleSubmit(onSubmit)} noValidate>
                  <div className="custom-scrollbar max-h-[50vh] overflow-y-auto flex flex-col gap-5 md:gap-6 px-6 py-4 md:px-8 md:py-6">
                    {filterElements}
                  </div>
                  <div className="flex items-center border-t-[1px] border-divider dark:border-divider-dark gap-4 font-semibold px-6 py-4 md:px-8 md:py-6">
                    <button className="bg-primary text-white px-6 py-[0.625rem] rounded-md dark:bg-primary-dark focus-visible:outline-white hover:bg-primary-hover dark:hover:bg-primary-dark-hover">
                      {t('apply')}
                    </button>
                    <button
                      type="button"
                      onClick={closeModal}
                      className="focus-visible:outline-white hover:opacity-80 transition-opacity px-6 py-[0.625rem] rounded-md"
                    >
                      {t('cancel')}
                    </button>
                    <button
                      type="button"
                      onClick={resetFilters}
                      className="focus-visible:outline-white hover:opacity-80 transition-opacity ml-auto"
                    >
                      {t('reset')}
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
