import { Outlet } from 'react-router-dom'
import Footer from '../components/Footer'
import Main from '../components/Main'
import Navbar from '../components/Navbar'
import { ToastContainer } from 'react-toastify'

export default function RootLayout() {
  return (
    <div className="min-h-screen flex flex-col">
      <Navbar />
      <Main title="Cleopatra Analytics">
        <Outlet />
      </Main>
      <Footer />
      <ToastContainer theme="colored" draggable={false} pauseOnFocusLoss={false} pauseOnHover={false} />
    </div>
  )
}
