import { useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import { createBrowserRouter, RouterProvider, createRoutesFromElements, Route } from 'react-router-dom'
import { toast } from 'react-toastify'

import { socket } from './utils/socket'

import RootLayout from './layouts/RootLayout'
import Homepage from './pages/Homepage'
import SingleSection from './components/Single/SingleSection'
import Login from './pages/Login' // Import the Login component
import ProtectedRoute from './components/ProtectedRoute' // Import ProtectedRoute
import 'react-toastify/dist/ReactToastify.css'
import About from "./pages/About.tsx";

function App() {
  const [isError, setIsError] = useState<boolean>(false)

  useEffect(() => {
    function onConnect() {
      console.log('Successfully connected to the server')
      setIsError(false)
    }

    function onError(err: any) {
      setIsError(true);
      Sentry.captureException(err);
    }

    socket.connect()
    socket.on('connect', onConnect)
    socket.on('connect_error', onError)

    return () => {
      socket.disconnect()
    }
  }, [])

  useEffect(() => {
    if (!isError) return
    toast.error('The server is not responding')
  }, [isError])

  const router = createBrowserRouter(
      createRoutesFromElements(
          <Route element={<RootLayout />}>
            <Route path="about" element={<About />} />
            <Route path="login" element={<Login />} />
            <Route path="logout" element={<Login />} />
              <Route index element={
                <ProtectedRoute>
                    <Homepage />
                </ProtectedRoute>} 
            />
            <Route
                path="analytics"
                element={
                  <ProtectedRoute>
                    <SingleSection />
                  </ProtectedRoute>
                }
            />
          </Route>
      )
  )

  return (
            <RouterProvider router={router} />
  )
}

export default App
