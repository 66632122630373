export type AnyObject = {
  [key: string]: any
}

export interface TableResponse {
  dayCount: number
  data: TableData[]
  filters: AnyObject
  defaultFilters: AnyObject
}

export interface TopRequest {
  totalWinRate?: FilterRange
  totalPnL?: FilterRange
  totalDeposit?: FilterRange
  totalAverage?: FilterRange
  total?: FilterRange

  realizedWinRate?: FilterRange
  realizedPnL?: FilterRange
  realizedDeposit?: FilterRange
  realizedAverage?: FilterRange
  realized?: FilterRange

  tokenCount?: FilterRange
  transfersCount?: FilterRange

  minLiquidity?: FilterSelect
  minVolume?: FilterSelect
  scamRate?: FilterSelect
}

export interface FilterRange {
  start?: number
  end?: number
}

export interface FilterSelect {
  start: number
}

export interface TableData {
  active: boolean
  wallet: string
  blockchain: string
  startTimestamp: number | null
  endTimestamp: number | null
  liquidity: number | null
  apiCalls: {
    [key: string]: number
  }
  stats: {
    realised: Stat
    total: Stat
  }
  tokenCount?: number
  transfersCount?: number
  scamRate?: number
  lastTrade?: Date
}

export interface Stat {
  pnl: number
  rateAverage: number
  rateDeposit: number
  rateTotal: number
  winrate: number
}

export enum SortingType {
  ASC,
  DESC
}

export interface Column {
  shortName: string
  description: string
  width: number
  sortFunction: (a: TableData, b: TableData, sortOrder: SortingType) => number
}

// Filters

export enum SelectType {
  VALUES,
  DROPDOWN
}

interface IFilterBase {
  key: string
  name: string
  unit: '$' | '%' | ''
}

interface IFilterValues extends IFilterBase {
  type: SelectType.VALUES
}

interface IFilterDropdown extends IFilterBase {
  type: SelectType.DROPDOWN
  options: string[]
}

export type Filter = IFilterValues | IFilterDropdown
