import QuestionCard from '../components/QuestionCard'
import { FAQCard } from '../types/faq'

const questions: FAQCard[] = [
    {
        id: 1,
        title: 'About',
        text: `CLEOPATRA ANALYTICAL SERVICE FOR MONITORING CRYPTO WALLETS

WE SCAN ALL BLOCKCHAIN NETWORK TRANSACTIONS TO OBTAIN COMPREHENSIVE
INFORMATION ABOUT ALL TRANSACTIONS WHERE INDIVIDUAL PARTICIPANTS IN THE DEX MARKET HAVE MADE A PROFIT AND PROVIDE YOU WITH A LIST OF THE TOP100 SUCCESSFUL WALLETS
`
    }
]

export default function About() {
    const questionCards = questions.map(item => <QuestionCard key={item.id} item={item} />)

    return (
        <section id="faq">
            <div className="video-wrapper mt-6">
                <iframe
                    src="https://drive.google.com/file/d/1y8g2m3ZQIi3oFQupU4vdqZn5H2CEyiyu/preview"
                    title="Google Drive video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
            </div>
            <div className="mt-6 flex flex-col gap-4 md:gap-6">{questionCards}</div>
        </section>
    )
}
