import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import dayjs from 'dayjs'

import SingleTable from './SingleTable'
import StatCard from './StatCard'
import { AxiosResponse } from 'axios'
import { SingleWalletInfo, SingleWalletResponse } from '../../types/single'
import { useSearchParams } from 'react-router-dom'
import { trackEvent } from '../../utils/mixpanel'
import api from "../../utils/api.ts";

dayjs.extend(customParseFormat)

type SingleSectionProps = {
  isHomepage?: boolean
}

type Inputs = {
  wallet: string
  timeframe: string
  liquidity: string
}

export default function SingleSection({ isHomepage = false }: SingleSectionProps) {
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()

  const [walletInfo, setWalletInfo] = useState<SingleWalletInfo | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const initialTimeframe = Object.fromEntries(searchParams.entries()).timeframe ?? 'monthly'
  const [timeframeType, setTimeframeType] = useState<string | null>(initialTimeframe)
  

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<Inputs>()

  async function onSubmit(data: Inputs) {
    if (isLoading) return
    const { wallet, timeframe, liquidity } = data
    reset()

    const body: any = {
      wallet
    }

    if (timeframe) {
      const dayRegex = /^\d{1,3}$/
      const datesRegex = /^(\d{1,2}[.]\d{1,2}[.]\d{4}|\d{2})\s(\d{1,2}[.]\d{1,2}[.]\d{4}|\d{2})$/
      const matchDays = timeframe.match(dayRegex)
      const matchDates = timeframe.match(datesRegex)

      if (matchDays) {
        body.dayCount = Number(matchDays?.[0])
      } else if (matchDates) {
        const startDate = dayjs(matchDates[1], 'DD.MM.YYYY').unix()
        const endDate = dayjs(matchDates[2], 'DD.MM.YYYY').unix()

        body.startTimestamp = startDate
        body.endTimestamp = endDate
      }
    }

    if (data.liquidity) {
      body.liquidity = Number(liquidity)
    }

    if (isHomepage) {
      const queryParams = Object.entries(body)
        .map(([key, value]) => `${key}=${value}`)
        .join('&')

      setTimeout(() => window.open(`${location.origin}/analytics?${queryParams}`, '_blank'))
      return
    }

    setSearchParams(body)
  }

  useEffect(() => {
    if (!searchParams.get('wallet') || isHomepage) return

    async function fetchSingle() {
      const body = Object.fromEntries(searchParams.entries())

      try {
        setIsLoading(true)
        trackEvent('Analytics unloading', body)
        
        const params = Object.fromEntries(searchParams.entries());
        
        const res: AxiosResponse<SingleWalletResponse> = 
          await api.get(`${import.meta.env.VITE_API}/top/${params.wallet}/${timeframeType ?? params.timeframe ?? 'monthly'}`)

        // There's no data in the cache
        if (res.data.process === false) {
          sessionStorage.removeItem('main-id')
          setIsLoading(false)
          return
        }

        // There is data
        if ('walletInfo' in res.data) {
          setWalletInfo(res.data.walletInfo)
          setIsLoading(false)
        }
      } catch (error: any) {
        toast.error(error?.message)
        console.error(error?.message)
        setIsLoading(false)
      }
    }

    fetchSingle()
  }, [isHomepage, searchParams, timeframeType])

  return (
    <section className="mt-16 md:mt-20" id="single">
      <h2 className="text-h2 font-bold">{t('single')}</h2>

      {isHomepage ? (
        <div></div>
        ) : (
        <div className="flex gap-4 mb-4">
          {['monthly', 'weekly', 'daily'].map((option) => (
            <button
            key={option}
          className={`px-4 py-2 rounded-md font-semibold ${timeframeType === option ? 'bg-line text-white' : 'bg-blue-700 dark:bg-blue-700'}`}
          onClick={() => setTimeframeType(option)}
        >
          {t(option)}
          </button>
          ))}
        </div>
      )}
      
      <form
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
        className={`mt-6 flex flex-col gap-3 text-dark-gray dark:text-white form-single md:flex-row`}
      >
        <div className="form-inputs grid gap-3 flex-grow">
          <input
            {...register('wallet', { required: true, pattern: /0x?[0-9a-fA-F]{40}/g })}
            type="text"
            placeholder={t('walletAddress')}
            className="w-full px-4 py-3 rounded-md font-medium text-dark dark:text-white placeholder:text-dark-gray dark:bg-blue dark:placeholder:text-very-light-blue md:-order-4"
            style={{ outline: errors.wallet ? '1px solid #E85D75' : '' }}
          />
        </div>
        <div className="flex gap-3">
          <button
            type="submit"
            aria-label="Start search"
            disabled={isLoading}
            className="bg-white dark:bg-blue rounded-md flex justify-center items-center h-[48px] w-full md:w-[48px] disabled:cursor-not-allowed"
          >
            <i className="fa-solid fa-search fa-lg" aria-hidden="true"></i>
          </button>
        </div>
      </form>

      {isLoading ? (
        <div className="flex items-center justify-center mt-14">
          <div className="inline-block h-12 w-12 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        walletInfo?.active && (
          <>            
            <div className="mt-8 flex flex-col gap-8 md:mt-14 md:gap-10 xl:flex-row">
              <StatCard stat={walletInfo.stats.realised} title={t('realized')} />
              <StatCard stat={walletInfo.stats.total} title={t('total')} />
            </div>

            <div className="mt-8 md:mt-14">
              <div className="mb-4 flex flex-col gap-4 md:flex-row md:items-center md:justify-between text-[0.75rem] text-dark-gray dark:text-very-light-blue md:text-[1rem]">
                <div className="flex items-center gap-4">
                  <h2 className="text-dark dark:text-white text-h4 font-bold md:text-h3">{t('table')}</h2>
                  <p>
                    {dayjs.unix(walletInfo.endTimestamp / 1000).format('DD.MM.YYYY HH:mm')}
                  </p>
                  {walletInfo.liquidity > 0 && <p>${walletInfo.liquidity}</p>}
                </div>
                <p>{walletInfo.wallet}</p>
              </div>
              <SingleTable tokenList={walletInfo.tokenList} />
            </div>
          </>
        )
      )}
    </section>
  )
}