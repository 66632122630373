export interface ResponseSample {
  status: 'success' | 'error'
}

export interface SuccessfulResponse extends ResponseSample {
  status: 'success'
  mainId: string
}

export interface ErrorResponse extends ResponseSample {
  status: 'error'
  message: string
}

export type SingleUploadResponse = SuccessfulResponse | ErrorResponse

export interface SingleWalletBase {
  mainId: string
  process?: boolean
}

export interface SingleWalletSuccess extends SingleWalletBase {
  mainId: string
  walletInfo: SingleWalletInfo
}

export interface SingleWalletNotFound extends SingleWalletBase {
  process: false
}

export interface SingleWalletProcessing extends SingleWalletBase {
  process: true
}

export type SingleWalletResponse = SingleWalletSuccess | SingleWalletNotFound | SingleWalletProcessing

export interface SingleWalletInfo {
  wallet: string
  blockchain: string
  startTimestamp: number
  endTimestamp: number
  liquidity: number
  apiCalls: {
    [key: string]: number
  }
  active: boolean
  raw: boolean
  stats: {
    realised: SingleWalletStat
    total: SingleWalletStat
  }
  tokenList: SingleTokenInfo[]
}

export interface SingleWalletStat {
  pnl: number
  winrate: number
  rateTotal: number
  rateAverage: number
  rateDeposit: number
}

export interface SingleTokenInfo {
  symbol: string
  address: string
  diffCount: number
  remainUsd: number
  feeUsd: number
  diffCountUsd: number
  diffPrice: number
  lastTimestamp: number
  volume: number
  liquidity: number
  contractName: string
  buy: SingleTokenTransfer
  sell: SingleTokenTransfer
  active: boolean
  dexguru: boolean
  scam: boolean
  stableCoin: boolean
  realised: boolean
}

export interface SingleTokenTransfer {
  count: number
  countUsd: number
  priceUsd: number
  countTransfers: number
}

export enum SortingType {
  OFF,
  ASC,
  DESC
}

export interface Column {
  text: string
  width: number
  sortFunction: (a: SingleTokenInfo, b: SingleTokenInfo, sortingType: SortingType) => number
}
